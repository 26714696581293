import React from "react";
import { refreesAPI } from "../config";
import { useLocation, useNavigate } from "react-router-dom";
import RadixUiForm from "../components/Form/radixUiForm";
import useLogin from "../hooks/useLogin";

function LogInReferees() {
  const navigate = useNavigate();
  const location = useLocation();
  const route = location.pathname;

  const { inputItemsLogIn, loading, onClickSubmitLogIn } = useLogin(
    location,
    navigate,
    refreesAPI.login,
    route
  );

  return (
    <RadixUiForm
      title={"تسجيل دخول الحكم"}
      submitLabel={"تسجيل الدخول"}
      inputItems={inputItemsLogIn}
      loading={loading}
      onClickSubmit={onClickSubmitLogIn}
    />
  );
}

export default LogInReferees;
