import React, { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { fcmToken, userAPI } from "../config";
import { useNavigate, useLocation } from "react-router-dom";
import NotifyToast from "../components/notifyToast";
import useDeviceId from "../hooks/useDeviceId";
import RadixUiForm from "../components/Form/radixUiForm";
import { Context } from "../utiles/Context";

function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [forceStrongePassword, setForceStrongePassword] = useState(false);
  const [showFiles, setShowFiles] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [deviceId] = useDeviceId();

  const { loading, setLoading, setAccessToken, setRefreshToken } =
    useContext(Context);

  // Dropzone file function
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setShowFiles(true);
      if (file.size / 1000000 > 2) {
        setShowFiles(false);
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        setBase64Image(base64);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  useEffect(() => {
    if (password.length > 0 && password.length < 6) {
      setForceStrongePassword(true);
    } else {
      setForceStrongePassword(false);
    }
  }, [password]);

  const checkServerInvalidMessage = (item) => {
    if (item.type === "password") {
      return forceStrongePassword;
    }
    return null;
  };
  const inputItemsRegister = [
    {
      type: "name",
      label: "الأسم الكامل",
      autoFocus: true,
      inputValue: name,
      onChange: (e) => {
        let newValue = e.target.value;
        setName(newValue);
      },
    },
    {
      type: "email",
      label: "البريد الالكتروني",
      typeMismatch: "الرجاء ادخال البريد بشكل صحيح",
      inputValue: email,
      onChange: (e) => {
        let newValue = e.target.value;
        setEmail(newValue);
      },
    },
    {
      type: "password",
      label: "الرمز السري",
      typeMismatch: "الرجاء ادخال أكثر من ٦ رموز",
      inputValue: password,
      onChange: (e) => {
        let newValue = e.target.value;
        setPassword(newValue);
      },
    },
  ];
  const logInImmediately = async () => {
    let payload = {
      provider: "e",
      fcm_token: fcmToken,
      device_id: deviceId,
      email: email,
      password: password,
    };

    await axios
      .post(userAPI.logEndPoint, payload)
      .then((res) => {
        if (res.status === 200) {
          setAccessToken(res.data.result.access_token);
          setRefreshToken(res.data.result.refresh_token);

          setLoading(false);
          if (location.state && location.state.from) {
            navigate(location.state.from);
          } else {
            navigate("/");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        NotifyToast("حدث خطأ أثناء تسجيل الدخول!");
        setLoading(false);
      });
  };
  const onClickSubmitRegister = useCallback(
    async (e) => {
      e.preventDefault();

      var bodyFormData = new FormData();
      bodyFormData.append("full_name", name);
      bodyFormData.append("email", email);
      bodyFormData.append("password", password);
      base64Image !== "" &&
        showFiles &&
        bodyFormData.append("photo", base64Image);

      const uploadCondition = name !== "" && email !== "" && password !== "";

      if (uploadCondition) {
        if (password.length < 6) {
          setForceStrongePassword(true);
        } else {
          setLoading(true);
          await axios
            .post(userAPI.signEndPoint, bodyFormData)
            .then((res) => {
              if (res.status === 201) {
                logInImmediately();
              }
            })
            .catch((error) => {
              if (error.response.data.error_code === "E-IAM-EMAIL-EXIST") {
                NotifyToast(
                  "البريد الالكتروني مسجل!",
                  "تسجيل الدخول",
                  "/login",
                );
              } else {
                NotifyToast("حدث خطأ أثناء إنشاء الحساب!");
              }
              setLoading(false);
            });
        }
      }
    },
    [name, email, password, base64Image, showFiles],
  );

  return (
    <>
      <RadixUiForm
        title={"إنشاء حساب جديد"}
        NavLinkLabel={"هل لديك حساب مسجل؟"}
        ToNavLink={"/login"}
        submitLabel={"إنشاء الحساب"}
        inputItems={inputItemsRegister}
        onClickSubmit={onClickSubmitRegister}
        loading={loading}
        checkServerInvalidMessage={checkServerInvalidMessage}
        onDrop={onDrop}
        showFiles={showFiles}
      />
    </>
  );
}

export default Register;
