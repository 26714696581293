import { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../utiles/Context";
import axios from "axios";
import { refreesAPI } from "../config";
import NotifyToast from "../components/notifyToast";
import useDeviceId from "./useDeviceId";
import useExchangeToken from "./useExchangeToken";

function useVoting(route) {
  const [singleVoteImage, setSingleVoteImage] = useState(null);
  const [permission, setPermission] = useState(false);
  const ratingRoute = route === "/rate-images";
  const [deviceId] = useDeviceId();

  const { setLoading, refereeHeaders } = useContext(Context);

  const { getNewAccessToken } = useExchangeToken(route);

  useEffect(() => {
    setLoading(true);
    const checkPayload = () => {
      let payload = {
        type: ratingRoute ? "CaptureForRating" : "CaptureForSorting",
      };

      if (ratingRoute) {
        payload = {
          min_accepted_sorting: 2.5,
          ...payload,
        };
      }
      return payload;
    };

    axios
      .post(refreesAPI.nextDesign, checkPayload(), { headers: refereeHeaders })
      .then((res) => {
        if (res.status === 200) {
          setSingleVoteImage(res.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (
          e.response.status === 403 &&
          e.response.data ===
            `you haven't a permission for ${ratingRoute ? "rating" : "sorting"}`
        ) {
          setPermission(true);
          setSingleVoteImage(null);
          setLoading(false);
        }
        if (
          e.response.status === 400 &&
          e.response.data !== "record not found"
        ) {
          getNewAccessToken();
        } else if (
          e.response.status === 400 &&
          e.response.data === "record not found"
        ) {
          setSingleVoteImage(null);
          setLoading(false);
        }

        console.log(e);
        setLoading(false);
      });
  }, []);

  const checkPayload = (id, value) => {
    const unique = deviceId;

    let payload = {
      design_id: id,
      value: value,
    };
    if (ratingRoute) {
      payload = {
        unique: unique,
        ...payload,
      };
    }
    return payload;
  };
  const onClickVoting = useCallback(
    (id, value) => {
      setLoading(true);
      console.log(checkPayload(id, value));
      axios
        .post(
          ratingRoute ? refreesAPI.rate : refreesAPI.sort,
          checkPayload(id, value),
          {
            headers: refereeHeaders,
          },
        )
        .then((res) => {
          console.log(res);
          if (typeof window !== "undefined") {
            setLoading(false);
            window.location.reload();
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          if (e.response.status === 400) {
            setLoading(true);
            NotifyToast("حدث خطأ أثناء تقييم الصورة!");
            setTimeout(() => {
              if (typeof window !== "undefined") {
                window.location.reload();
              }
            }, 3000);
          }
        });
    },
    [singleVoteImage, ratingRoute],
  );

  return {
    singleVoteImage,
    onClickVoting,
    permission,
  };
}

export default useVoting;
