import React, { useContext, useState } from "react";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { Context } from "../../utiles/Context";
import useVoting from "../../hooks/useVoting";
import Voting from "../../components/Form/voting";
import { useLocation } from "react-router-dom";

function SortImages() {
  const { loading, refereesAccessToken } = useContext(Context);
  const [hover, setHover] = useState(0);

  const location = useLocation();
  const { singleVoteImage, onClickVoting, permission } = useVoting(
    location.pathname,
  );

  const StarRating = () => {
    return (
      <div
        dir="ltr"
        className="flex h-full w-full flex-col items-center justify-center gap-4"
      >
        <Rating
          max={4}
          size="large"
          onChange={(event, newValue) => {
            onClickVoting(singleVoteImage.id, newValue);
          }}
          onChangeActive={(event, newHover) => {
            if (newHover === -1) {
              setHover(0);
            } else {
              setHover(newHover);
            }
          }}
          precision={0.5}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
      </div>
    );
  };
  return (
    <Voting
      permission={permission}
      stepTitle={"مرحلة فرز الصور"}
      singleVoteImage={singleVoteImage}
      loading={loading}
      refereesAccessToken={refereesAccessToken}
      hover={hover}
      votingChildForm={<StarRating />}
      route={location.pathname}
      votingButton={
        <button
          onClick={() => {
            onClickVoting(singleVoteImage.id, 0);
          }}
          className="submitButton mt-3 rounded-[5px] bg-red9
                   text-white focus:shadow-[0_0_0_2px] focus:shadow-blackA8
               lg:hover:bg-red11"
        >
          تجاهل الصورة
        </button>
      }
    />
  );
}

export default SortImages;
