import axios from "axios";
import { useContext } from "react";
import { Context } from "../utiles/Context";
import { refreesAPI, userAPI } from "../config";
import NotifyToast from "../components/notifyToast";

function useExchangeToken(route) {
  const {
    refereesRefreshToken,
    setRefereesAccessToken,
    setLoading,
    userHeaders,
    setAccessToken,
  } = useContext(Context);

  const getNewAccessToken = () => {
    let payload = {
      refresh_token: refereesRefreshToken,
    };
    axios
      .post(
        !route ? userAPI.newAccessToken : refreesAPI.exchangeToken,
        !route ? (payload = {}) : payload,
        !route && { headers: userHeaders },
      )
      .then((res) => {
        if (!route) {
          setAccessToken(res.data.access_token);
        } else {
          setRefereesAccessToken(res.data.access_token);
        }
        if (typeof window !== "undefined") {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
        if (
          e.response.status === 400 &&
          e.response.data ===
            "400 Bad Request: invalid_grant: Token is not active"
        ) {
          setLoading(true);
          NotifyToast("إنتهت صلاحية تسجيل دخولك، حاول التسجيل من جديد");
          setTimeout(() => {
            if (typeof window !== "undefined") {
              if (!route) {
                window.localStorage.removeItem("accessToken");
                window.localStorage.removeItem("refreshToken");
              } else {
                window.localStorage.removeItem("refereesAccessToken");
                window.localStorage.removeItem("refereesRefreshToken");
              }

              window.location.reload();
            }
          }, 3000);
        }
      });
  };
  return { getNewAccessToken };
}

export default useExchangeToken;
