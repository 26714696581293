import React, { useContext } from "react";

import WhiteLogo from "../../image/logo/award-logo/whiteLogo.svg";
import BlackLogo from "../../image/logo/award-logo/blackLogo.svg";
import RadixUiAvatar from "../radixUiAvatar";
import UserInformation from "./userInformation";
import { NavLink, useLocation } from "react-router-dom";
import { Context } from "../../utiles/Context";
import useLogout from "../../hooks/useLogout";

function NavBar({ refereesAccessToken }) {
  const { srcoll } = useContext(Context);

  const location = useLocation();
  let validdRoute = location.pathname !== "/";

  const { handleLogOut } = useLogout(location.pathname);

  const menuNavbarItem = [
    {
      label: "عن المسابقة",
      id: "About",
    },
    {
      label: "الجوائز",
      id: "Awards",
    },
    // {
    //   label: "الشروط",
    //   id: "Requirements",
    // },
    {
      label: "اللجنة",
      id: "Referees",
    },
  ];
  function handleClick(id) {
    const element = document.getElementById(id);
    if (element)
      element.scrollIntoView({
        behavior: "instant",
        block: "center",
        inline: "nearest",
      });
  }

  return (
    <div className={`fixed left-0 right-0 top-0 z-[75] h-auto w-full bg-white`}>
      <div
        className="m-auto flex max-w-screen-xl items-center
         justify-between pl-4 pr-0 lg:pl-6 lg:pr-2"
      >
        <div className="my-1 flex h-full flex-row items-center justify-start">
          <NavLink
            to={"/"}
            className="flex-shrink-0 cursor-pointer overflow-hidden align-middle"
          >
            <RadixUiAvatar
              srcImage={BlackLogo}
              altImage="Logo"
              classNameImage="h-[120px] w-[120px] object-contain transitionNav"
            />
          </NavLink>

          {/* {!validdRoute && (
            <div
              className={`${
                srcoll ? "text-blackA12" : "text-white"
              } mr-10 hidden flex-row gap-8 lg:flex`}
            >
              {menuNavbarItem.map((item) => {
                return (
                  <div
                    id="menuNavbar"
                    className="cursor-pointer"
                    onClick={() => {
                      handleClick(item.id);
                    }}
                    key={item.id}
                  >
                    <h1 id="menuNavbarText" className="text-[1rem] leading-[2]">
                      {item.label}
                    </h1>
                  </div>
                );
              })}
              <div id="menuNavbar" className="cursor-pointer">
                <NavLink
                  to={"/share"}
                  className="flex-shrink-0 cursor-pointer overflow-hidden align-middle"
                >
                  <h1 id="menuNavbarText" className="text-[1rem] leading-[2]">
                    مشاركتي
                  </h1>
                </NavLink>
              </div>
            </div>
          )} */}
        </div>
        {/* <UserInformation
          validdRoute={validdRoute}
          handleLogOut={handleLogOut}
          location={location}
        /> */}
        {refereesAccessToken && (
          <button onClick={handleLogOut} className="alertButton">
            تسجيل الخروج
          </button>
        )}
      </div>
    </div>
  );
}

export default NavBar;
