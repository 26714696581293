import React, { useContext } from "react";
import NavBar from "../components/Navbar/navbar";
import Awards from "../components/Landing/awards";
import Requirement from "../components/Landing/requirements";
import Referees from "../components/Landing/referees";
import About from "../components/Landing/Hero/about";
import Footer from "../components/Footer/footer";
import UserRate from "./Gallery/user-rate";
import Spinner from "../components/spinner";
import { Context } from "../utiles/Context";

function Landing() {
  const { loading } = useContext(Context);
  return (
    <>
      {loading && <Spinner spinnerContainer="spinerContainerFixed" />}
      <NavBar />
      <div className="landingContainer">
        <UserRate />
        <Footer />
      </div>
    </>
  );
}

export default Landing;
