import React from "react";
import RadixUiAvatar from "../radixUiAvatar";
import AlabaseahLogo from "../../image/logo/alabaseah.png";
import HaqybatLogo from "../../image/logo/haqybat.png";
import AllLogo from "../../image/logo/allApp.png";

import { InstagramLogoIcon } from "@radix-ui/react-icons";
const logosItem = [
  {
    src: AllLogo,
    alt: "مركز التطبيقات البرمجية الإسلامية",
    href: "https://hmomen.mk.iq/",
  },
  {
    src: HaqybatLogo,
    alt: "تطبيق حقيبة المؤمن",
    href: "https://hmomen.mk.iq/",
  },
  {
    src: AlabaseahLogo,
    alt: "العتبة العباسية المقدسة",
    href: "https://alkafeel.net/",
  },
];

function Footer() {
  return (
    <div className="landingSubContainer mt-10">
      <div className="">
        <h1 className="sectionTitle">عاشوراء حول العالم برعاية</h1>
      </div>

      <div
        className="m-auto mt-[40px] grid
       w-full grid-cols-1 gap-4 md:w-[75%] md:grid-cols-2 lg:grid-cols-3"
      >
        {logosItem.map((item) => {
          return (
            <a
              target="_blank"
              rel="noreferrer"
              href={item.href}
              key={item.alt}
              className="group m-auto h-[150px] w-[150px] 
             flex-shrink-0 overflow-hidden align-middle"
            >
              <RadixUiAvatar
                classNameImage={
                  "h-full w-full object-cover grayscale group-hover:grayscale-0 transitionProp"
                }
                srcImage={item.src}
                altImage={item.alt}
              />
            </a>
          );
        })}
      </div>
      <div className="mb-4 mt-4 w-full">
        <div className="mb-4 h-[1px] w-full bg-gray-300" />
        <div className="flex w-full flex-col items-center justify-center gap-4 lg:flex-row lg:justify-between lg:gap-0">
          <div>
            <p className="text-black-800">
              © جميع الحقوق محفوظة - حقيبة المؤمن 2023
            </p>
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            className="transitionProp group rounded-sm bg-blackA12 p-1.5 lg:hover:bg-blackA12/80"
            href="https://www.instagram.com/ashuraphotoaward/"
          >
            <InstagramLogoIcon className="transitionProp h-7 w-7 text-white lg:group-hover:scale-[1.05]" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
