import { useEffect, useState } from "react";

function useCountdown() {
  const second = 1000,
    minute = second * 60,
    hour = minute * 60,
    day = hour * 24;

  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    let today = new Date("10/01/2023");
    let futureDate = new Date(today.getTime());
    futureDate.setDate(today.getDate() + 10);
    const countDown = futureDate.getTime();

    const x = setInterval(() => {
      const now = new Date().getTime(),
        distance = countDown - now;

      setTime({
        days: Math.floor(distance / day),
        hours: Math.floor((distance % day) / hour),
        minutes: Math.floor((distance % hour) / minute),
        seconds: Math.floor((distance % minute) / second),
      });

      if (distance < 0) {
        setIsExpired(true);
        clearInterval(x);
      }
    }, 1000);

    return () => clearInterval(x);
  }, []);

  const totalTime = time.days + time.hours + time.minutes + time.seconds;
  return { isExpired, time, totalTime };
}

export default useCountdown;
