import Landing from "./pages/landing";
import LogIn from "./pages/logIn";
import Register from "./pages/register";
import { HashRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useLocalStorage from "./hooks/useLocalStorage";
import { useState, useEffect } from "react";
import PrivateRoute from "./components/privateRoute";
import axios from "axios";
import { userAPI } from "./config";
import LogInReferees from "./pages/logIn-referees";
import SortImages from "./pages/Gallery/sort-images";
import RateImages from "./pages/Gallery/rate-images";
import { Context } from "./utiles/Context";
import { Headers } from "./utiles/headers";
import useExchangeToken from "./hooks/useExchangeToken";
import UserRate from "./pages/Gallery/user-rate";

function App() {
  const [srcoll, setScroll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
  const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", null);
  const [userFiles, setUserFiles] = useState([]);
  const [userInfo, setUserInfo] = useState({
    email: "",
    full_name: "",
    photo: "",
  });
  const [newName, setNewName] = useState("");
  const [refereesAccessToken, setRefereesAccessToken] = useLocalStorage(
    "refereesAccessToken",
    null,
  );
  const [refereesRefreshToken, setRefereesRefreshToken] = useLocalStorage(
    "refereesRefreshToken",
    null,
  );
  const userHeaders = Headers(accessToken);
  const userUploadFilesHeaders = Headers(accessToken, {
    maxBodyLength: Infinity,
    "Content-Type": "multipart/form-data",
  });
  const refereeHeaders = Headers(refereesAccessToken);

  // const { getNewAccessToken } = useExchangeToken();
  // useEffect(() => {
  //   if (accessToken) {
  //     if (typeof window !== "undefined") {
  //       window.localStorage.removeItem("accessToken");
  //       window.localStorage.removeItem("refreshToken");
  //     }
  //   }
  //   // const fetchFilesData = () => {
  //   //   if (accessToken) {
  //   //     setLoading(true);
  //   //     axios
  //   //       .get(userAPI.getMyDesigns, {
  //   //         headers: userHeaders,
  //   //       })
  //   //       .then((res) => {
  //   //         if (res.status === 200) {
  //   //           setUserFiles(res.data);
  //   //           setLoading(false);
  //   //         }
  //   //       })
  //   //       .catch((e) => {
  //   //         if (e.response.status === 401 || e.response.status === 400) {
  //   //           getNewAccessToken();
  //   //         }
  //   //         console.log(e);
  //   //         setLoading(false);
  //   //       });
  //   //   }
  //   // };
  //   // const getUserInfo = async () => {
  //   //   if (accessToken) {
  //   //     await axios
  //   //       .get(userAPI.getMyAccount, {
  //   //         headers: userHeaders,
  //   //       })
  //   //       .then((res) => {
  //   //         setUserInfo({
  //   //           email: res.data.result.email,
  //   //           full_name: res.data.result.full_name,
  //   //           photo: res.data.result.photo ? res.data.result.photo : "",
  //   //         });
  //   //         setNewName(res.data.result.full_name);
  //   //         setLoading(false);
  //   //       })
  //   //       .catch((e) => {
  //   //         if (e.response.status === 401 || e.response.status === 400) {
  //   //           getNewAccessToken();
  //   //         }
  //   //         console.log(e);
  //   //         setLoading(false);
  //   //       });
  //   //   }
  //   // };
  //   // getUserInfo();
  //   // fetchFilesData();
  // }, [accessToken]);

  // useEffect(() => {
  //   const handleSrcollEvent = () => {
  //     if (window.scrollY > 0 && window.scrollY > 10) {
  //       setScroll(!srcoll);
  //     } else {
  //       setScroll(false);
  //     }
  //   };
  //   const handleMouseMove = (e) => {
  //     if (window.innerWidth > 1024) {
  //       document.querySelectorAll(".bubbles").forEach(function (move) {
  //         var xValue = move.getAttribute("x-value");
  //         var yValue = move.getAttribute("y-value");
  //         var x = `${(e.clientX * xValue) / 1000}px`;
  //         var y = `${(e.clientY * yValue) / 1000}px`;

  //         move.style.transform = `translate3d(${x},${y},0px)`;
  //         move.style.transformStyle = "preserve-3d";
  //         move.style.willChange = "transform";
  //         move.style.transition = "all 600ms linear";
  //       });
  //     }
  //   };
  //   window.addEventListener("mousemove", handleMouseMove);
  //   window.addEventListener("scroll", handleSrcollEvent);
  // }, []);

  return (
    <>
      <Context.Provider
        value={{
          loading,
          setLoading,
          setRefereesAccessToken,
          setRefereesRefreshToken,
          refereesAccessToken,
          refereesRefreshToken,
          setAccessToken,
          setRefreshToken,
          accessToken,
          srcoll,
          userFiles,
          userInfo,
          newName,
          setNewName,
          userHeaders,
          userUploadFilesHeaders,
          refereeHeaders,
        }}
      >
        <HashRouter>
          <ToastContainer
            autoClose={3000}
            rtl
            limit={1}
            icon={false}
            closeButton={false}
          />

          <div dir="rtl">
            <Routes>
              <Route path="/" element={<Landing />} />
              {/* <Route
                path="/signup"
                element={
                  <PrivateRoute accessToken={!accessToken} To={"/"}>
                    <Register />
                  </PrivateRoute>
                }
              />
              <Route
                path="/login"
                element={
                  <PrivateRoute accessToken={!accessToken} To={"/"}>
                    <LogIn />
                  </PrivateRoute>
                }
              /> */}
              <Route
                path="/login-referees"
                element={
                  <PrivateRoute accessToken={!refereesAccessToken} To={"/"}>
                    <LogInReferees />
                  </PrivateRoute>
                }
              />
              {/* <Route
                path="/sort-images"
                element={
                  <PrivateRoute
                    accessToken={refereesAccessToken}
                    To={"/login-referees"}
                  >
                    <SortImages />
                  </PrivateRoute>
                }
              /> */}
              <Route
                path="/rate-images"
                element={
                  <PrivateRoute
                    accessToken={refereesAccessToken}
                    To={"/login-referees"}
                  >
                    <RateImages />
                  </PrivateRoute>
                }
              />
              {/* <Route path="/user-rate" element={<UserRate />} /> */}
            </Routes>
          </div>
        </HashRouter>
      </Context.Provider>
    </>
  );
}

export default App;
