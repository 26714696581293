import React from "react";
import Spinner from "../spinner";
import { NavLink } from "react-router-dom";
import * as Form from "@radix-ui/react-form";
import DropzoneFile from "./dropzoneFile";
import RadixUiAvatar from "../radixUiAvatar";
import BlackLogo from "../../image/logo/award-logo/blackLogo.svg";

function RadixUiForm({
  title,
  onClickSubmit,
  inputItems,
  loading,
  ToNavLink,
  NavLinkLabel,
  submitLabel,
  checkServerInvalidMessage,
  onDrop,
  showFiles,
}) {
  return (
    <div className="allFormConatiner">
      <div
        className="h-full w-full items-center
      justify-center"
      >
        <div className="flex h-full w-full flex-col">
          <div className="mb-10 flex items-center justify-center">
            <NavLink
              to={"/"}
              className="h-[100px] w-[100px] flex-shrink-0 overflow-hidden"
            >
              <RadixUiAvatar
                srcImage={BlackLogo}
                classNameImage="w-full h-full"
              />
            </NavLink>
          </div>
          <div>
            <h1 className="text-center text-4xl leading-3">{title}</h1>
            <Form.Root className="formRoot" onSubmit={onClickSubmit}>
              {inputItems.map((item) => {
                return (
                  <Form.Field
                    key={item.type}
                    className="formField"
                    name={item.type}
                    serverInvalid={
                      checkServerInvalidMessage &&
                      checkServerInvalidMessage(item)
                    }
                  >
                    <div className="formFieldContainer">
                      <Form.Label className="formFieldLabel">
                        <h1>{item.label}</h1>
                      </Form.Label>
                      <Form.Message
                        className="formFieldMessage"
                        match="valueMissing"
                      >
                        <h2>مطلوب</h2>
                      </Form.Message>
                      <Form.Message
                        forceMatch={
                          checkServerInvalidMessage &&
                          checkServerInvalidMessage(item)
                        }
                        className="formFieldMessage"
                        match="typeMismatch"
                      >
                        <h2>{item.typeMismatch}</h2>
                      </Form.Message>
                    </div>
                    <Form.Control asChild>
                      <input
                        value={item.inputValue}
                        onChange={item.onChange}
                        autoFocus={!loading && item.autoFocus}
                        className="formFieldInput"
                        type={item.type}
                        required
                      />
                    </Form.Control>
                  </Form.Field>
                );
              })}
              {onDrop && (
                <DropzoneFile
                  showFiles={showFiles}
                  onDrop={onDrop}
                  accept={{
                    "image/jpeg": [".jpeg"],
                  }}
                  extension={"jpeg فقط"}
                  dropzoneLabel="الصورة الشخصية"
                  directiveLabel={
                    <div className="formFieldMessage text-black-800">
                      <h2>إختياري</h2>
                    </div>
                  }
                />
              )}

              <Form.Submit asChild>
                <button className="submitButton">{submitLabel}</button>
              </Form.Submit>
              {loading && (
                <Spinner spinnerContainer="spinerContainerAbsolute" />
              )}
            </Form.Root>
            {ToNavLink && NavLinkLabel && (
              <NavLink
                to={ToNavLink}
                className="transitionProp mt-5 flex items-center justify-center
                 text-black-600 no-underline underline-offset-4 lg:hover:underline"
              >
                <h1>{NavLinkLabel}</h1>
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RadixUiForm;
