import React from "react";
import { useDropzone } from "react-dropzone";
import DocsCheckIcon from "../../icon/docsCheck";
import Spinner from "../spinner";
import * as Popover from "@radix-ui/react-popover";
import ErrorIcon from "../../icon/errorTriangle";
function DropzoneFile({
  onDrop,
  accept,
  extension,
  dropzoneLabel,
  directiveLabel,
  loading,
  info,
  showFiles,
  loadingPercentage,
}) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: accept,
    onDrop,
  });

  const files = acceptedFiles.map((file) => {
    let fileSize = file.size / 1000000;
    return (
      <li key={file.path}>
        {fileSize}MB - {file.path}
      </li>
    );
  });
  return (
    <div className="formField">
      <div className="formFieldContainer">
        <div className="formFieldLabel">
          <div className="w-full h-full inline-flex items-start">
            <h1>{dropzoneLabel}</h1>
            {info && (
              <Popover.Root>
                <Popover.Trigger asChild>
                  <button
                    className="text-blackA12 bg-violet4 focus:shadow-violet8
                     h-[30px] w-[30px]
                     appearance-none items-center 
                     flex justify-center rounded-full
                      focus:shadow-[0_0_0_2px] focus:outline-none mr-1 p-1"
                    aria-label="Close"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                      <path
                        fillRule="evenodd"
                        d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </Popover.Trigger>
                <Popover.Portal>
                  <Popover.Content
                    className="rounded-[6px] bg-white p-[25px]
              shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]
               focus:outline-none z-[77] w-[260px] items-center 
               justify-center"
                  >
                    {info}
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            )}
          </div>
        </div>
        {directiveLabel && directiveLabel}
      </div>

      <div {...getRootProps({ className: "dropzoneContainer" })}>
        <input {...getInputProps()} />
        <div className="flex flex-col justify-center items-center">
          <p>إسحب ملف الصورة هنا، أو أنقر لتحديد الملف</p>
          <p>{extension}</p>
        </div>
        {files.length !== 0 && loading && (
          <Spinner
            loadingPercentage={loadingPercentage}
            spinnerContainer="spinerContainerAbsolute"
          />
        )}
      </div>
      {files.length !== 0 && !loading && (
        <aside
          className="flex flex-col justify-center items-center
         mt-[8px] text-gray-700 gap-1"
        >
          <div className="inline-flex items-start">
            {showFiles ? (
              <>
                <DocsCheckIcon className="w-5 h-5 text-blueA8" />
                <h2 className="text-[16px] mr-0.5">تم تحميل ملف الصورة</h2>
              </>
            ) : (
              <>
                <ErrorIcon className="w-5 h-5 text-red9" />
                <h2 className="text-[16px] mr-0.5">
                  يجب أن لا يتجاوز حجم الصورة 2MB
                </h2>
              </>
            )}
          </div>
          <ul className="text-center text-[15px]">{files}</ul>
        </aside>
      )}
    </div>
  );
}

export default DropzoneFile;
