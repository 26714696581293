const baseURL = "https://api.hmomen.com/user-api";
const apiKey = "1a502da1-00e4-49bb-987a-ca3ba232a846";
const designerChallengeBaseUrl = "https://api.hmomen.com/design-challenge/api";
const fcmToken =
  "eEN2IyMjY0NjU3NjY5NjM2NTVmNjk2NDIyM2EyMjYxNzM2YjZhNmE2NDY2MjIyYzIyNjY2MzZkNWY3NDZmNmI2NTZlMjIzYTIyNjE3MzMxNmI2YjZhNmEyMjJjMjI3MDZjNjE3NDY2NmY3MjZkMjIzYTIyNTA2ZjczNzQ2ZDYxNmU1MjcE";

const userAPI = {
  signEndPoint: `${baseURL}/user?api_key=${apiKey}`,
  logEndPoint: `${baseURL}/user/login?api_key=${apiKey}`,
  uploadImagesEndPoint: `${designerChallengeBaseUrl}/fs/upload`,
  addDesignImages: `${designerChallengeBaseUrl}/design`,
  getMyDesigns: `${designerChallengeBaseUrl}/design/my-designs?order_by=desc&order_field=created_at&status=SUBMITTED`,
  deleteDesignEndpoint: (id) => {
    return `${designerChallengeBaseUrl}/design/${id}`;
  },
  getMyAccount: `${baseURL}/user/me?api_key=${apiKey}`,
  updateMyAcoount: `${baseURL}/user?api_key=${apiKey}`,
  logOut: `${baseURL}/user/logout?device_id=string&api_key=${apiKey}`,
  newAccessToken: `${baseURL}/user/token/access?api_key=${apiKey}`,
  getAllUersDesigns: (uniqueId, limit) => {
    return `${designerChallengeBaseUrl}/design?limit=${limit}&order_by=desc&order_field=random&committee_rating=2.5&image_size=large&hide_for_unique=${uniqueId}`;
  },
  userRate: `${designerChallengeBaseUrl}/voting/vote-design`,
};
const refreesAPI = {
  login: `${designerChallengeBaseUrl}/committee-iam/login`,
  exchangeToken: `${designerChallengeBaseUrl}/committee-iam/exchange-tokens`,
  nextDesign: `${designerChallengeBaseUrl}/voting/capture-next-design?image_size=large`,
  sort: `${designerChallengeBaseUrl}/voting/committee-sort-voting`,
  rate: `${designerChallengeBaseUrl}/voting/committee-vote-design`,
  logOut: `${designerChallengeBaseUrl}/committee-iam/logout`,
};

export { userAPI, refreesAPI, fcmToken };
