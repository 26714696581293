import { useCallback, useContext, useState } from "react";
import useDeviceId from "./useDeviceId";
import { fcmToken } from "../config";
import { Context } from "../utiles/Context";
import axios from "axios";
import NotifyToast from "../components/notifyToast";

function useLogin(location, navigate, logEndPoint, route) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [deviceId] = useDeviceId();
  const trueRoute = route === "/login-referees";

  const {
    loading,
    setLoading,
    setAccessToken,
    setRefreshToken,
    setRefereesAccessToken,
    setRefereesRefreshToken,
  } = useContext(Context);

  const checkPayload = () => {
    let payload = {
      email: email,
      password: password,
    };
    if (!trueRoute) {
      payload = {
        provider: "e",
        fcm_token: fcmToken,
        device_id: deviceId,
        ...payload,
      };
    }
    return payload;
  };

  const inputItemsLogIn = [
    {
      type: "email",
      label: "البريد الالكتروني",
      typeMismatch: "الرجاء ادخال البريد بشكل صحيح",
      inputValue: email,
      onChange: (e) => {
        let newValue = e.target.value;
        setEmail(newValue);
      },
      autoFocus: true,
    },
    {
      type: "password",
      label: "الرمز السري",
      typeMismatch: "الرجاء ادخال الرمز السري بشكل صحيح",
      inputValue: password,
      onChange: (e) => {
        let newValue = e.target.value;
        setPassword(newValue);
      },
    },
  ];
  const onClickSubmitLogIn = useCallback(
    async (e) => {
      e.preventDefault();

      if (password !== "" && email !== "") {
        setLoading(true);
        await axios
          .post(logEndPoint, checkPayload())
          .then((res) => {
            if (res.status === 200) {
              if (trueRoute) {
                setRefereesAccessToken(res.data.access_token);
                setRefereesRefreshToken(res.data.refresh_token);
              } else {
                setAccessToken(res.data.result.access_token);
                setRefreshToken(res.data.result.refresh_token);
              }

              setLoading(false);
              if (location.state && location.state.from) {
                navigate(location.state.from);
              } else {
                navigate("/");
              }
            }
          })
          .catch((error) => {
            console.log(error);
            NotifyToast("حدث خطأ أثناء تسجيل الدخول!");
            setLoading(false);
          });
      }
    },
    [email, password],
  );

  return {
    inputItemsLogIn,
    loading,
    onClickSubmitLogIn,
  };
}

export default useLogin;
