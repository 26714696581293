import React, { forwardRef } from "react";
import * as Avatar from "@radix-ui/react-avatar";

const RadixUiAvatar = forwardRef(function RadixUiAvatar(props, ref) {
  const {
    srcImage,
    classNameImage,
    altImage,
    fallbackLabel,
    classNameFallback,
    style,
  } = props;
  // console.log(ref !== null && ref.current);

  return (
    <Avatar.Root>
      <Avatar.Image
        ref={ref}
        style={style}
        src={srcImage}
        className={classNameImage}
        alt={altImage}
      />
      {fallbackLabel && (
        <Avatar.Fallback className={classNameFallback} delayMs={600}>
          {fallbackLabel}
        </Avatar.Fallback>
      )}
    </Avatar.Root>
  );
});

export default RadixUiAvatar;
