import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children, accessToken, To }) => {
  const location = useLocation();

  return accessToken ? (
    children
  ) : (
    <Navigate to={To} replace state={{ from: location.pathname }} />
  );
};

export default PrivateRoute;
