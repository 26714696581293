import React, { useState } from "react";
import Spinner from "../spinner";
import NavBar from "../Navbar/navbar";
import RadixUiAvatar from "../radixUiAvatar";
import DialogWidget from "./dialog";

function Voting({
  loading,
  refereesAccessToken,
  stepTitle,
  singleVoteImage,
  sum,
  votingChildForm,
  route,
  hover,
  votingButton,
  permission,
}) {
  const ratingRoute = route === "/rate-images";
  const [deg, setDeg] = useState(0);

  const handleChangeDeg = () => {
    setDeg(deg + 90);
  };

  return (
    <>
      {loading && <Spinner spinnerContainer="spinerContainerFixed" />}
      <NavBar refereesAccessToken={refereesAccessToken} />
      <div className="paddingYContainer paddingXContainer m-auto h-full max-w-screen-xl">
        <div className="my-24 h-full w-full">
          <div className="mb-10 flex h-full w-full items-center justify-center">
            <h1 className="sectionTitle">{stepTitle}</h1>
          </div>
          {singleVoteImage !== null ? (
            <div className="flex h-full w-full justify-between pt-10">
              <div className="sticky top-[175px] z-[12] h-full w-[50%]">
                <div
                  className="relative flex h-full w-full flex-col
             rounded-[5px] border-[1px] border-gray-300 bg-gray-100/20 p-5"
                >
                  <div className="mb-5 flex items-center justify-center">
                    <h1 className="text-center text-2xl">
                      ما هي نسبة تقييمك للصورة؟
                    </h1>
                  </div>
                  {/* Chid Form Voting */}
                  {votingChildForm}

                  <div className="mt-2 flex flex-col justify-center">
                    <h1
                      className={`text-[20px] ${
                        sum >= 30 || hover >= 2 ? "text-green10" : "text-red10"
                      }`}
                    >
                      التقييم الكلي: {ratingRoute ? `${sum}%` : `${hover}/4`}
                    </h1>
                    <a
                      href={singleVoteImage.archive_file}
                      className="mt-2 flex
                  cursor-pointer underline-offset-4 hover:underline"
                    >
                      <h1 className="ml-1 text-[20px]">
                        تحميل ملف أرشيف الصورة؟
                      </h1>
                    </a>
                    <button
                      onClick={handleChangeDeg}
                      className="submitButton rounded-[5px]
                   bg-blueA9 text-white focus:shadow-[0_0_0_2px]
               focus:shadow-blackA8 lg:hover:bg-blueA11"
                    >
                      تدوير الصورة
                    </button>
                    {/* Button, become ignore button in sort page and sendRating in rate page */}
                    {votingButton}
                  </div>
                </div>
              </div>

              <div className="h-full w-[50%] flex-shrink-0 pr-20">
                <DialogWidget
                  OverlayBg={"bg-blackA12/90"}
                  triggerChid={
                    <div className="h-full w-full cursor-pointer">
                      <RadixUiAvatar
                        style={{
                          transform: `rotate(${deg}deg)`,
                        }}
                        classNameImage="w-full h-full object-cover transition-all duration-[0.4s] ease-in-out"
                        srcImage={singleVoteImage.designed_image}
                        altImage="Vote Image"
                        classNameFallback="w-full h-[300px]
                               bg-blackA3 border-[1px]
                                border-blackA4 flex justify-center items-center"
                        fallbackLabel={<Spinner />}
                      />
                    </div>
                  }
                  content={
                    <RadixUiAvatar
                      style={{
                        transform: `rotate(${deg}deg)`,
                      }}
                      classNameImage="votingImage"
                      srcImage={singleVoteImage.designed_image}
                      altImage="Vote Image"
                      classNameFallback="w-full h-full border-blackA4 flex justify-center items-center"
                      fallbackLabel={<Spinner />}
                    />
                  }
                />
              </div>
            </div>
          ) : (
            <div className="mt-24 flex h-full w-full items-center justify-center">
              {!loading && (
                <h2 className="text-xl text-blackA11 lg:text-2xl">
                  {permission
                    ? "هذا الحساب ليس لديه صلاحيات هذه المرحلة!"
                    : "لا توجد صور جديدة لتقييمها، شكراً لكم"}
                </h2>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Voting;
