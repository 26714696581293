import { useCallback, useContext } from "react";
import NotifyToast from "../components/notifyToast";
import axios from "axios";
import { Context } from "../utiles/Context";
import { logOut, refreesAPI, userAPI } from "../config";

function useLogout(route) {
  let trueRoute = route === "/sort-images" || route === "/rate-images";

  const { refereesRefreshToken, setLoading, userHeaders, refereeHeaders } =
    useContext(Context);

  let payload = {
    refresh_token: refereesRefreshToken,
  };

  const handleLogOut = useCallback(() => {
    setLoading(true);
    axios
      .post(
        trueRoute ? refreesAPI.logOut : userAPI.logOut,
        trueRoute ? payload : {},
        {
          headers: trueRoute ? refereeHeaders : userHeaders,
        },
      )
      .then((res) => {
        console.log(res.data);
        if (typeof window !== "undefined") {
          if (trueRoute) {
            window.localStorage.removeItem("refereesAccessToken");
            window.localStorage.removeItem("refereesRefreshToken");
          } else {
            window.localStorage.removeItem("accessToken");
            window.localStorage.removeItem("refreshToken");
          }
          setLoading(false);
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        NotifyToast("حدث خطأ أثناء تسجيل الخروج!");
        if (e.response.status === 401 || e.response.status === 400) {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            if (typeof window !== "undefined") {
              setLoading(false);
              window.location.reload();
            }
          }, 3000);
        }
      });
  }, []);

  return { handleLogOut };
}

export default useLogout;
