import React from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorTriangleIcon from "../icon/errorTriangle";
import { CheckIcon } from "@radix-ui/react-icons";

function NotifyToast(message, navLabel, navLink) {
  return toast.error(
    <div className="w-full h-full flex flex-col justify-start text-black-800 z-[1000]">
      <ErrorTriangleIcon className="w-6 h-6 text-red-primary" />

      <div className="mt-1">
        <h2 className="text-black-800">{message}</h2>
      </div>

      {navLabel && navLink && (
        <NavLink
          className="w-full flex justify-center
             items-center text-black-800
             rounded-sm my-2 py-0.5 border-[1px] lg:hover:bg-gray-50 border-gray-300 transitionProp"
          to={navLink}
        >
          {navLabel}
        </NavLink>
      )}
    </div>,
    {
      position: toast.POSITION.BOTTOM_RIGHT,
    }
  );
}

export default NotifyToast;
