import { useEffect, useState } from "react";

function useDeviceId() {
  const [deviceId, setDeviceId] = useState(null);
  function generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }

  useEffect(() => {
    let uuid = localStorage.getItem("device_id");
    if (!uuid) {
      uuid = generateUUID();
      localStorage.setItem("device_id", uuid);
    }
    setDeviceId(uuid);
  }, []);

  return [deviceId];
}

export default useDeviceId;
