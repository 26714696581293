import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
function DialogWidget({ triggerChid, content, openModal, OverlayBg }) {
  return (
    <Dialog.Root open={openModal && openModal}>
      {triggerChid && <Dialog.Trigger asChild>{triggerChid}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Overlay
          className={`fixed inset-0 z-[76] ${OverlayBg} data-[state=open]:animate-overlayShow`}
        />
        <Dialog.Content
          dir="rtl"
          className={`realtive fixed left-[50%] top-[50%] z-[77] h-screen w-full translate-x-[-50%] translate-y-[-50%] overflow-x-hidden overflow-y-hidden
          focus:outline-none data-[state=open]:animate-contentShow`}
        >
          {content}
          <Dialog.Close
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="group absolute right-5 top-5 rounded-3xl border-[1px] border-blueA8 bg-gray-50 transition-all duration-200 ease-in hover:rounded-xl"
          >
            <Cross1Icon className="h-9 w-9 p-1.5 text-blackA12 transition-all duration-200 ease-in group-hover:text-blackA11" />
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default DialogWidget;
