import React, { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../../utiles/Context";
import Spinner from "../../components/spinner";
import axios from "axios";
import { userAPI } from "../../config";
import RadixUiAvatar from "../../components/radixUiAvatar";
import useDeviceId from "../../hooks/useDeviceId";
import NotifyToast from "../../components/notifyToast";
import * as LottiePlayer from "@lottiefiles/lottie-player";

import { HeartIcon, HeartFilledIcon } from "@radix-ui/react-icons";
import useLocalStorage from "../../hooks/useLocalStorage";
import DialogWidget from "../../components/Form/dialog";
import useCountdown from "../../hooks/useCountdown";
function UserRate() {
  const [allPhoto, setAllPhoto] = useState([]);
  const [ratedPhotos, setRatedPhotos] = useLocalStorage("ratedPhotos", []);
  const [deviceId] = useDeviceId();
  const [limit, setLimit] = useState(50);

  const { isExpired } = useCountdown();

  const { loading, setLoading } = useContext(Context);

  const isAccept = (id) => {
    return ratedPhotos.some((r) => r.id === id);
  };

  useEffect(() => {
    setLoading(true);
    if (!isExpired) {
      setTimeout(() => {
        if (deviceId) {
          axios
            .get(userAPI.getAllUersDesigns(deviceId, limit))
            .then((res) => {
              setAllPhoto(res.data);
              setLoading(false);
            })
            .catch((e) => {
              console.log(e);
              setLoading(false);
            });
        }
      }, 1000);
    }
  }, [deviceId, limit, isExpired]);

  useEffect(() => {
    setLoading(true);
    if (isExpired) {
      setTimeout(() => {
        setAllPhoto([]);
        setLoading(false);
      }, 1000);
    }
  }, [isExpired]);

  const clickUserRating = useCallback(
    (id) => {
      let payload = {
        unique: deviceId,
        design_id: id,
      };
      if (!isAccept(id)) {
        setLoading(true);
        axios
          .post(userAPI.userRate, payload)
          .then((res) => {
            // console.log(res.data);
            let newRatedPhotos = [
              ...ratedPhotos,
              {
                id: id,
              },
            ];
            setRatedPhotos(newRatedPhotos);

            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            NotifyToast("حدث خطأ أثناء تقييم الصورة!");
          });
      }
    },
    [deviceId, ratedPhotos],
  );

  const falseRender = allPhoto && allPhoto.length === 0;

  return (
    <>
      {falseRender || isExpired ? (
        <div className="flex h-screen w-full flex-col items-center justify-center">
          {!loading && (
            <>
              <lottie-player
                autoplay
                mode="normal"
                src="https://lottie.host/85df13ba-331e-461f-92b4-78403ffc231c/mTHAZ2epgV.json"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              ></lottie-player>
              <h1 className="text-xl md:text-3xl">
                {isExpired ? "إنتهت مرحلة التصويت" : "شكراً لكم على التصويت"}
              </h1>
              {isExpired && (
                <h2 className="mt-2 text-lg md:text-2xl">
                  سيتم نشر التفاصيل الخاصة بالفائزين قريباً...
                </h2>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="landingSubContainer my-28">
          <div className="mt-10 flex flex-col items-center justify-center">
            <h1 className="sectionTitle">تصويت الجمهور</h1>
            <h2 className="mt-3 text-center text-lg md:mt-5 md:text-xl">
              يمكنك التصويت على الصورة التي تعجبك عن طريق النقر عليها
            </h2>
          </div>

          <div className="flex h-full w-full flex-col items-center justify-center">
            <div className="my-10 w-full columns-1 gap-3 overflow-hidden sm:columns-2 lg:columns-3">
              {allPhoto.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="transitionProp group relative mb-3 cursor-pointer"
                    onClick={() => {
                      clickUserRating(item.id);
                    }}
                  >
                    <TopLayer
                      sourceImage={item.designed_image}
                      cssProps={`${
                        isAccept(item.id)
                          ? ""
                          : "invisible lg:group-hover:visible"
                      } `}
                      child={
                        isAccept(item.id) ? (
                          <HeartFilledIcon className="h-10 w-10 text-red10" />
                        ) : (
                          <HeartIcon className="h-10 w-10 text-gray-300" />
                        )
                      }
                    />
                    <RadixUiAvatar
                      srcImage={item.designed_image}
                      classNameImage="w-full h-full rounded-[10px]"
                      altImage="Vote Image"
                      classNameFallback="w-full h-[300px]
                                 bg-blackA3 border-[1px]
                                  border-blackA4 flex justify-center items-center rounded-[10px]"
                      fallbackLabel={<Spinner />}
                    />
                  </div>
                );
              })}
            </div>
            {limit < 50 && limit === allPhoto.length && (
              <div>
                <button
                  onClick={() => {
                    setLimit(limit + 10);
                  }}
                  className="rounded-sm border-[2px] border-blueA5 bg-gray-50 p-2 text-lg transition-all duration-100 ease-linear lg:hover:border-blackA7"
                >
                  عرض المزيد
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const TopLayer = ({ cssProps, child, sourceImage }) => {
  return (
    <div
      className={`${cssProps} transitionProp absolute top-0 flex h-full w-full
                       items-center justify-center rounded-[10px] bg-blackA12/50`}
    >
      <div className="flex h-full w-full flex-col items-center justify-center gap-3">
        {child}
        <DialogWidget
          OverlayBg={"bg-blackA12/90"}
          triggerChid={
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="invisible hidden w-full lg:flex lg:group-hover:visible"
            >
              <button
                className="mx-auto w-[75%] cursor-zoom-in
                              rounded-sm  bg-red11 px-2 py-1 text-lg text-white"
              >
                تكبير الصورة
              </button>
            </div>
          }
          content={
            <RadixUiAvatar
              classNameImage="votingImage"
              srcImage={sourceImage}
              altImage="Vote Image"
              classNameFallback="w-full h-full border-blackA4 flex justify-center items-center"
              fallbackLabel={<Spinner />}
            />
          }
        />
      </div>
    </div>
  );
};
export default UserRate;
