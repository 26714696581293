import React from "react";
import Wallpaper from "../../../image/Ashura/wallpaper.jpg";
import RadixUiAvatar from "../../radixUiAvatar";
import ImageBubbles from "./imageBubbles";
import CountDownContainer from "./countDownContainer";
import AboutScript from "./aboutScript";

function About() {
  return (
    <div id="About" className="w-full h-full">
      <div className="relative w-full max-h-screen">
        <div
          className="w-full h-full
         absolute top-[20%] bottom-0 text-white z-[10]"
        >
          <div className="hidden xl:block ">
            <AboutScript />
          </div>
          <div className="hidden lg:block">
            <CountDownContainer />
          </div>
        </div>

        <ImageBubbles />
        <RadixUiAvatar
          classNameImage="w-full h-full z-[9]"
          altImage="wallpaper"
          srcImage={Wallpaper}
        />
      </div>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="block lg:hidden">
          <CountDownContainer />
        </div>

        <div className="block mt-[40px] xl:hidden xl:mt-0 w-full h-full">
          <AboutScript />
        </div>
      </div>
    </div>
  );
}

export default About;
