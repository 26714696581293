import React, { useCallback, useContext } from "react";
import RadixUiAvatar from "../radixUiAvatar";
import UserIcon from "../../icon/user";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import Spinner from "../spinner";
import { Context } from "../../utiles/Context";
import axios from "axios";
import { userAPI } from "../../config";
import DialogWidget from "../Form/dialog";

function UserInformation({ validdRoute, handleLogOut, location }) {
  const {
    srcoll,
    accessToken,
    loading,
    userFiles,
    userInfo,
    newName,
    setNewName,
    setLoading,
    userHeaders,
  } = useContext(Context);

  const handleUpdateUserInfo = useCallback(() => {
    if (newName.length > 0) {
      setLoading(true);
      var bodyFormData = new FormData();
      bodyFormData.append("full_name", newName);

      axios
        .patch(userAPI.updateMyAcoount, bodyFormData, {
          headers: userHeaders,
        })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            if (typeof window !== "undefined") {
              window.location.reload();
            }
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [newName]);

  return (
    <>
      {accessToken &&
        userInfo &&
        location.pathname !== "/sort-images" &&
        location.pathname !== "/rate-images" && (
          <DialogWidget
            OverlayBg="bg-blackA9"
            cssProps="max-h-[85vh] w-[90vw] max-w-[450px] rounded-[6px] p-[25px]"
            content={
              <>
                <div className="mt-2 p-2">
                  <h1 className="m-0 text-[17px] text-mauve12">
                    ملفات مشاركتك
                  </h1>

                  {userFiles.length > 0 ? (
                    <fieldset className="mb-5 mt-[10px] flex items-center justify-center gap-4">
                      {userFiles.map((item, index) => {
                        let newIndex = index + 1;
                        return (
                          <div key={item.id} className="h-[100px] w-[100px]">
                            <RadixUiAvatar
                              classNameImage="w-full h-full rounded-full object-cover"
                              srcImage={item.designed_image}
                              fallbackLabel={newIndex}
                              classNameFallback="w-full h-full rounded-full
                               bg-gray-100 flex justify-center items-center border-[1px] border-blackA8"
                            />
                          </div>
                        );
                      })}
                    </fieldset>
                  ) : (
                    <Dialog.Description className="mb-5 mt-[10px] text-[15px] leading-normal text-mauve11">
                      لم يتم إضافة أي ملف
                    </Dialog.Description>
                  )}
                </div>
                <div className="p-2">
                  <h1 className="m-0 text-[17px] text-mauve12">تعديل أسمك</h1>
                  <Dialog.Description className="mb-5 mt-[10px] text-[15px] leading-normal text-mauve11">
                    يمكنك تعديل أسمك من هنا. أضغط على زر حفظ التغيير عندما تنتهي
                    أو يمكنك تسجيل الخروج.
                  </Dialog.Description>
                  <fieldset className="mb-[15px] flex items-center gap-5">
                    <label
                      className="w-[90px] text-right text-[15px] text-violet11"
                      htmlFor="name"
                    >
                      <h1>الأسم الكامل</h1>
                    </label>
                    <input
                      className="inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-violet11 shadow-[0_0_0_1px] shadow-violet7 outline-none focus:shadow-[0_0_0_2px] focus:shadow-violet8"
                      type="name"
                      value={newName}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        setNewName(newValue);
                      }}
                    />
                  </fieldset>

                  <div className="mt-[25px] flex flex-row items-center justify-center">
                    <button
                      onClick={handleUpdateUserInfo}
                      className="ml-3 inline-flex
                   h-[35px] items-center
                    justify-center rounded-[4px] bg-green4
                     px-[15px] font-medium
                      leading-none text-green11
                       hover:bg-green5
                        focus:shadow-[0_0_0_2px] focus:shadow-green7 focus:outline-none"
                    >
                      حفظ التغيير
                    </button>

                    <button onClick={handleLogOut} className="alertButton">
                      تسجيل الخروج
                    </button>
                  </div>
                </div>

                <Dialog.Close asChild>
                  <button
                    className="absolute left-[10px] top-[10px]
                   inline-flex h-[25px] w-[25px]
                    appearance-none items-center justify-center
                     rounded-full text-violet11 
                     hover:bg-violet4 focus:shadow-[0_0_0_2px]
                      focus:shadow-violet7 focus:outline-none"
                    aria-label="Close"
                  >
                    <Cross2Icon />
                  </button>
                </Dialog.Close>
                {loading && (
                  <Spinner spinnerContainer="spinerContainerAbsolute" />
                )}
              </>
            }
            triggerChid={
              <div
                className={`z-[11] border-[3px] ${
                  srcoll || validdRoute
                    ? "border-gray-400 bg-gray-300"
                    : "border-gray-300 bg-white"
                } transitionNav h-14 w-14
                flex-shrink-0 cursor-pointer overflow-hidden rounded-full align-middle`}
              >
                {userInfo.photo !== "" ? (
                  <RadixUiAvatar
                    srcImage={userInfo.photo}
                    classNameImage="h-full w-full rounded-full object-cover"
                  />
                ) : (
                  <UserIcon className="h-full w-full rounded-full object-cover" />
                )}
              </div>
            }
          />
        )}
    </>
  );
}

export default UserInformation;
