import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../utiles/Context";
import * as RadioGroup from "@radix-ui/react-radio-group";
import useVoting from "../../hooks/useVoting";
import Voting from "../../components/Form/voting";
import { useLocation } from "react-router-dom";

function RateImages() {
  const { loading, refereesAccessToken } = useContext(Context);
  const [conditions, setConditions] = useState([]);
  const location = useLocation();

  const isAcceptMainId = (id) => {
    return conditions.some((con) => con.id === id);
  };
  const isAcceptIdentifier = (id) => {
    return conditions.some((con) => con.identifier === id);
  };

  const calculateSum = () => {
    let add = 0;
    for (let i = 0; i < conditions.length; i++) {
      add += conditions[i].percentage;
    }
    return add;
  };
  const sum = calculateSum();

  let checkItems = [
    {
      label: "التكوين، أو ما يعرف بعناصر الصورة",
    },
    {
      label: "قصة الصورة وموضوعها",
    },
    {
      label: "المعالجة وتشمل التعريض، الألوان وجودة الصورة",
    },
    {
      label: "تكنيك إعدادات الصورة (العدسة)",
    },
  ];

  useEffect(() => {
    console.log("sum", sum);
  }, [sum]);

  const { singleVoteImage, onClickVoting, permission } = useVoting(
    location.pathname,
  );

  const CheckboxContainer = () => {
    return (
      <div className="my-4 flex h-full w-full flex-col gap-6">
        {checkItems.map((mainItem, mainIndex) => {
          return (
            <div key={mainIndex} className="h-full w-full">
              <div className="h-full w-full text-lg text-blackA12">
                <h2>
                  {mainIndex + 1} - {mainItem.label}
                </h2>
              </div>
              <form>
                <RadioGroup.Root
                  dir="rtl"
                  className="mt-4 flex h-full w-full flex-row items-center justify-start gap-2.5"
                  aria-label="View density"
                >
                  {Array.from([0, 5, 10, 15]).map((item, index) => {
                    let newSpecialIndex = `${index}:${mainIndex}`;

                    return (
                      <div key={index} className="flex items-center">
                        <RadioGroup.Item
                          className="focus:shadow-black h-[25px] w-[25px] cursor-default rounded-full bg-white shadow-[0_2px_10px] shadow-blackA7 outline-none hover:bg-violet3 focus:shadow-[0_0_0_2px]"
                          id={newSpecialIndex}
                          value={item}
                          checked={isAcceptIdentifier(newSpecialIndex)}
                          onClick={(e) => {
                            let newValue = parseInt(e.target.value);
                            if (isNaN(newValue)) return;
                            let updateConditions;

                            if (isAcceptMainId(mainIndex)) {
                              updateConditions = conditions.map((con) =>
                                con.id === mainIndex
                                  ? {
                                      ...con,
                                      percentage: newValue,
                                      identifier: newSpecialIndex,
                                    }
                                  : con,
                              );
                            } else {
                              updateConditions = [
                                ...conditions,
                                {
                                  id: mainIndex,
                                  identifier: newSpecialIndex,
                                  percentage: newValue,
                                },
                              ];
                            }
                            setConditions(updateConditions);
                          }}
                        >
                          <RadioGroup.Indicator className="relative flex h-full w-full items-center justify-center after:block after:h-[11px] after:w-[11px] after:rounded-[50%] after:bg-violet11 after:content-['']" />
                        </RadioGroup.Item>
                        <label
                          className="pr-[10px] text-[16px] leading-none"
                          htmlFor={newSpecialIndex}
                        >
                          {item}%
                        </label>
                      </div>
                    );
                  })}
                </RadioGroup.Root>
              </form>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <Voting
      permission={permission}
      singleVoteImage={singleVoteImage}
      votingChildForm={<CheckboxContainer />}
      sum={sum}
      stepTitle={"مرحلة تقييم الصور"}
      loading={loading}
      refereesAccessToken={refereesAccessToken}
      route={location.pathname}
      votingButton={
        <button
          onClick={() => {
            onClickVoting(singleVoteImage.id, sum);
          }}
          className="submitButton mt-3 rounded-[5px]"
        >
          أرسل تقييمك
        </button>
      }
    />
  );
}

export default RateImages;
