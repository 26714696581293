export function Headers(token, items) {
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  if (items) {
    headers = {
      ...headers,
      ...items,
    };
  }
  return headers;
}
