import React from "react";
import reqItems from "../../utiles/reqItems";
import { NavLink } from "react-router-dom";

function Requirement() {
  return (
    <div id="Requirements" className="landingSubContainer">
      <div>
        <h1 className="sectionTitle">شروط المسابقة</h1>
      </div>
      <div className="marignSubContainerContent w-full grid grid-cols-1 md:grid-cols-2 gap-10">
        {reqItems.map((item) => {
          return (
            <div key={item.number} className="inline-flex items-start gap-4">
              <h1 className="text-4xl md:text-5xl text-gray-300">
                {item.number}
              </h1>
              <div className="text-black-800">
                <h1 className="text-lg  mb-0.5">{item.sub}</h1>
                <p>{item.description}</p>
              </div>
            </div>
          );
        })}
      </div>
      <NavLink to={"/share"} className="group w-[40%] md:w-[25%] mt-[20px]">
        <button className="normalButton bg-blackA12">شارك الآن</button>
      </NavLink>
    </div>
  );
}

export default Requirement;
